import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from 'mineral-ui/themes';
import { Box, Text, Tooltip, Link } from 'mineral-ui';
import { css, Global } from '@emotion/core';
import emotionNormalize from 'emotion-normalize';
import useSiteMetadata from '../hooks/use-site-metadata';
import Gravatar from 'react-gravatar';
import tokens from 'mineral-ui-tokens';

const theme = createTheme({
  colors: {
    theme: 'indigo'
  }
});

const Layout = props => {
  const { children } = props;
  const { description, author, authorMail, twitterHandle, twitterUrl } = useSiteMetadata();
  return (
    <>
      <Global
        styles={css`
          ${emotionNormalize}
        `}
      />
      <ThemeProvider theme={theme}>
        <Box>
          <Box
            breakpoints={['narrow', 'medium', 'wide']}
            marginVertical={['2rem', '2rem', '2rem', '2rem']}
            marginHorizontal={['2rem', '2rem', 'auto', 'auto']}
            width={[null, null, 600, 600]}
          >
            <Box
              paddingBottom="4rem"
              marginBottom="1rem"
              css={{
                textAlign: 'center',
              }}
            >
              <Tooltip
                content={`${author}`}
                defaultIsOpen={true}
                isOpen={true}
                placement="bottom"
              >
                <Gravatar
                  email={authorMail}
                  size={150}
                  style={{
                    borderRadius: '50%',
                    border: `1px solid ${tokens.borderColor}`
                  }}
                />
              </Tooltip>
            </Box>
            <Box
              marginBottom="3rem"
              css={{
                textAlign: 'center'
              }}
            >
              <Text as="p">{description}</Text>
            </Box>
            {children}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
